.flip-card {
    background-color: transparent;
    width: 100%;
    height: 150px;
    perspective: 1000px;
    margin-bottom: 20px;
    border-radius: 12px; /* Increase border-radius for a smoother look */
}
  
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
    border-radius: 12px;
}
  
.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}
  
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
  
.flip-card-front {
    background-color: #4CAF50; /* Darker green for better contrast */
    color: white;
}
  
.flip-card-back {
    background-color: #0C7621;
    color: white;
    transform: rotateY(180deg);
}
